import { defineStore } from 'pinia';
import * as Realm from '~/static/urls/realm';
import useClearFetchStore from '@/stores/useClearFetchStore';
import { API_RESEND_ACTIVATION_LINK } from '~/static/urls/user';
import {
  REALM_CREATION,
  REALM_DELETION,
  REALM_LIST,
  REALM_RESEND_ACTIVATION_EMAIL,
  REALM_UPDATION
} from '@/utils/activity';
import { computed, ref } from 'vue';
import { useNuxtApp } from '#app';

const useRealmStore = defineStore(
  'realm',
  () => {
    const realmData = ref([]);
    const realmLists = ref([]);
    const realm = ref(null);
    const successMessage = ref(null);
    const clearObj = useClearFetchStore();

    const updateSuccess = (msg) => {
      successMessage.value = msg;
    };

    const clearRealm = () => {
      realm.value = null;
    };

    const handleList = async (realmRequestData) => {
      const query = [];
      for (const p in realmRequestData) {
        if (typeof realmRequestData[p] === 'object') {
          realmRequestData[p] = JSON.stringify(realmRequestData[p]);
        }
        query.push(encodeURIComponent(p) + '=' + encodeURIComponent(realmRequestData[p]));
      }
      await clearObj.clearGet(`${Realm.API_GET_URL}?${query.join('&')}`);
      if (clearObj.storeData) {
        realmData.value = clearObj.storeData.data;
        clearObj.setLastActivity(REALM_LIST);
      }
    };

    const resendActivation = async (userId) => {
      const url = computed(() => API_RESEND_ACTIVATION_LINK.replace('{userId}', userId));
      await clearObj.clearGet(url.value);
      if (clearObj.storeData) {
        updateSuccess(clearObj.storeData.message);
        clearObj.setLastActivity(REALM_RESEND_ACTIVATION_EMAIL);
      }
    };

    const handleView = async (id) => {
      await clearObj.clearGet(Realm.API_VIEW_URL.replace('{id}', id));
      if (clearObj.storeData) {
        realm.value = clearObj.storeData.data;
      }
    };

    const handleCreate = async (realmReqData) => {
      clearObj.updatePendingState(true);
      await clearObj.clearPost(Realm.API_GET_URL, realmReqData);

      if ([400, 422].includes(clearObj.status) && process.client) {
        useNuxtApp().$toast.error(clearObj.message);
      } else if (clearObj.storeData) {
        realm.value = clearObj.storeData.data;
        updateSuccess(clearObj.storeData.message);
        clearObj.setLastActivity(REALM_CREATION);
      }
      clearObj.updatePendingState(false);
    };

    const handleUpdate = async (id, reqRealmData) => {
      await clearObj.clearPut(Realm.API_UPDATE_URL.replace('{id}', id), reqRealmData);
      if ([400, 422].includes(parseInt(clearObj.status)) && process.client) {
        useNuxtApp().$toast.error(clearObj.message);
      } else if (clearObj.storeData) {
        realm.value = clearObj.storeData.data;
        updateSuccess(clearObj.storeData.message);
        clearObj.setLastActivity(REALM_UPDATION);
      }
    };

    const handleDelete = async (id) => {
      await clearObj.clearDelete(Realm.API_DELETE_URL.replace('{id}', id));
      if (clearObj.storeData) {
        realm.value = { id };
        updateSuccess(clearObj.storeData.message);
        clearObj.setLastActivity(REALM_DELETION);
      }
    };

    const getErrorObj = computed(() => clearObj.getError);

    const getError = computed(() => clearObj.error);

    const resetState = () => {
      realm.value = null;
      realmData.value = [];
    };

    //setRealmData
    const setRealmData = (data) => {
      realmLists.value = data;
    };

    //getRealmData
    const getRealmData = () => realmLists.value;

    const getListing = computed(() => {
      if (!realmData.value) {
        return [];
      }
      return realmData.value.list;
    });

    const getFrom = computed(() => {
      if (!realmData.value) {
        return 0;
      }
      return realmData.value.from;
    });

    const getTo = computed(() => {
      if (!realmData.value) {
        return 0;
      }
      return realmData.value.to;
    });

    const getCurrentPage = computed(() => {
      if (!realmData.value) {
        return 0;
      }
      return realmData.value.current_page;
    });

    const getLastPage = computed(() => {
      if (!realmData.value) {
        return 0;
      }
      return realmData.value.lastPage;
    });

    const getTotal = computed(() => {
      if (!realmData.value) {
        return 0;
      }
      return realmData.value.total;
    });

    const setErrorNull = () => {
      clearObj.setErrorNull();
    };

    const clearRealmData = () => {
      realmData.value = [];
      realmLists.value = [];
      realm.value = null;
      successMessage.value = null;
    };

    const lastAtivityLog = computed(() => clearObj.lastActivityLog);
    const isPending = computed(() => clearObj.pending);
    return {
      // state
      realm,
      realmData,
      successMessage,
      realmLists,

      // actions
      handleList,
      handleView,
      handleCreate,
      handleUpdate,
      handleDelete,
      resetState,
      resendActivation,
      updateSuccess,
      clearRealm,
      setErrorNull,
      setRealmData,
      getRealmData,
      clearRealmData,

      // getters
      getListing,
      getFrom,
      getTo,
      getCurrentPage,
      getLastPage,
      getTotal,
      getErrorObj,
      getError,
      isPending,
      lastAtivityLog
    };
  },
  {
    persist: false
  }
);

export default useRealmStore;
